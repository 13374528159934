<template>
  <div>
    <Toolbar class="mb-4">
      <template #left>
        From
        <Calendar v-model="start" style="margin: 0 10px" />
        To
        <Calendar v-model="end" style="margin: 0 10px" />
        <Button
          :loading="isLoading"
          @click="search()"
          label="Search"
          icon="pi pi-search"
          iconPos="right"
          class="p-button-text p-button-text"
        />
      </template>

      <template #right>
        <Button
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)"
        />
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers"
      :rows="10"
      dataKey="name"
      :rowHover="true"
      :selection.sync="selectedItems"
      :expandedRows.sync="expandedRows"
      :loading="loading"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100]"
      currentPageReportTemplate="show {first} to {last} from {totalRecords}"
    >
      <template #header>
        <div class="table-header">
          Massages Aufführen

          <div style="float: right">
            <!-- <Button
              icon="pi pi-download"
              class="p-button-help"
              @click="exportCSV($event)"
            /> -->
          </div>
        </div>
      </template>
      <template #empty>Keine Daten</template>
      <template #loading>Daten werden geladen, bitte warten... </template>
      <Column :expander="true" headerStyle="width: 3rem" />

      <Column
        filterMatchMode="contains"
        field="name"
        header="Name"
        :sortable="true"
      >
        <template #body="slotProps">
          {{ JSON.parse(slotProps.data.name).de }}
        </template>
      </Column>

      <Column filterMatchMode="contains" field="id" header="Price">
        <template #body="slotProps">
          {{
            slotProps.data.list
              .map((el) => el.pricesId.price)
              .reduce((a, b) => a + b, 0)
          }}
          €
        </template>
      </Column>

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            :value="slotProps.data.list"
            class="p-datatable-customers table table-striped"
          >
            <Column
              filterMatchMode="contains"
              field="name"
              header="Name"
              :sortable="true"
            >
              <template #body="slotProps">
                {{
                  slotProps.data.info.fname + " " + slotProps.data.info.lname
                }}
              </template>
            </Column>

            <Column filterMatchMode="contains" field="id" header="Massager">
              <template #body="slotProps">
                {{ slotProps.data.adminsId.fullName }}
              </template>
            </Column>

            <Column filterMatchMode="contains" field="date" header="Date">
              <template #body="slotProps">
                {{ $durationFormat(slotProps.data.start) }}
              </template>
            </Column>
            <Column
              filterMatchMode="contains"
              field="date"
              header="Time"
              :sortable="true"
            >
              <template #body="slotProps">
                {{
                  $durationFormatFull2(slotProps.data.start, slotProps.data.end)
                }}
              </template>
            </Column>

            <Column filterMatchMode="contains" field="price" header="Price">
              <template #body="slotProps">
                {{
                  slotProps.data.pricesId.minutes +
                  " Minuten = " +
                  slotProps.data.pricesId.price +
                  " €"
                }}
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";

import timeGridPlugin from "@fullcalendar/timegrid";
export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
  },
  data() {
    return {
      list: [],
      loading: true,

      usersList: [],
      expandedRows: [],
      oldList: [],
      start: null,
      end: null,
    };
  },
  methods: {
    getData() {
      this.$http.get(`appointments`).then(
        (response) => {
          this.loading = false;
          const list = [];

          for (const item of response.data.data) {
            list.push({ ...item, info: item });
          }
          this.oldList = list;
          const newList = [];

          for (const itm of list) {
            if (newList.find((el) => el.name == itm.massageId.name)) {
              const indx = newList.findIndex(
                (el) => el.name == itm.massageId.name
              );
              newList[indx].list.push(itm);
            } else {
              newList.push({ ...itm.massageId, list: [itm] });
            }
          }

          this.list = newList;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },

    search() {
      let list = [];
      if (this.start && !this.end) {
        const start = new Date(this.start).getTime();
        list = this.oldList.filter((item) => {
          return new Date(item.start).getTime() >= start;
        });
      }

      if (!this.start && this.end) {
        const end = new Date(this.end).getTime();
        list = this.oldList.filter((item) => {
          return new Date(item.start).getTime() <= end;
        });
      }

      if (this.start && this.end) {
        const start = new Date(this.start).getTime();
        const end = new Date(this.end).getTime();
        list = this.oldList.filter((item) => {
          return (
            new Date(item.start).getTime() >= start &&
            new Date(item.start).getTime() <= end
          );
        });
      }

      const newList = [];

      for (const itm of list) {
        if (newList.find((el) => el.name == itm.massageId.name)) {
          const indx = newList.findIndex((el) => el.name == itm.massageId.name);
          newList[indx].list.push(itm);
        } else {
          newList.push({ ...itm.massageId, list: [itm] });
        }
      }

      this.list = newList;
    },
  },
  created() {
    this.$http.get(`admins`).then((res) => {
      this.usersList = res.data;
    });
    this.getData();
  },
};
</script>

<style>
.xxx-checkbox .p-checkbox {
  margin-bottom: 22px;
  margin-right: 5px;
}
.fc .fc-view-harness {
  /* height: 856.296px !important; */
}

.fc .fc-scrollgrid-section table {
  width: 100% !important;
  /* height: 825px !important; */
}

.fc-scrollgrid-section-body .fc-scrollgrid-section table {
  width: 100% !important;
  /* height: 856.296px !important; */
}
</style>
